// COMPONENTS
import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Page from 'templates/Page'
import Tags from 'templates/Tags'
import PostList from 'BlogPost/PostList'
// APIS
import { getUrlParams } from 'apis/Browser'

// Blog posts listings page
const BlogPostsPage = () => {
  const [urlTag, setUrlTag] = useState('all')

  // Search for tags if they exist
  useEffect(() => {
    const { tag } = getUrlParams()
    setUrlTag(tag ? tag : 'all')
  }, [])

  const data = useStaticQuery(
    graphql`
      query BlogIndexQuery {
        allMarkdownRemark(
          filter: { frontmatter: { path: { ne: "INDEX" } } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                subtitle
                path
                date
                author
                image
                layout
                tags
              }
              excerpt
            }
          }
        }
        tags: allMarkdownRemark(limit: 2000) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `
  )
  // console.log(urlTag)

  return (
    <Page content={{ title: 'blog posts' }}>
      {/* <SEO title={title} />
      <Title title={title} subtitle={subtitle} imageUrl={image} />
      <Body markdown={body} /> */}
      <Tags tags={data.tags} tagChange={setUrlTag} />
      <PostList posts={data.allMarkdownRemark.edges} tagFilter={urlTag} />
    </Page>
  )
}

export default BlogPostsPage
